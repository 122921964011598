<template>
  <div>

    <b-form @submit.stop.prevent="formOnSubmit">
      <!-- Input Name -->
      <b-form-group
        id="input-group-name"
        label="Nama Kategori:"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Kategori"
        >
        </b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Input Description -->
      <b-form-group
        id="input-group-description"
        label="Deskripsi:"
        label-for="input-description"
      >
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          rows="3"
          placeholder="Deskripsi"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Button -->
      <template v-if="purpose == 'modal'">
        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          class="ml-2"
          variant="default"
          @click="$bvModal.hide('modal-expense-category')"
        >
          Batal
        </b-button>
      </template>
    </b-form>

  </div>
</template>

<script>

import validation from '@/core/modules/ValidationModule.js'
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    form: Object,
    route: String,
    editTarget: String,
    purpose: String,
  },

  data() {
    return {
      error: {
        name: '',
        description: ''
      }
    }
  },

  methods: {

    async formOnSubmit() {
      let response = await module.submit(this.form, this.route + this.editTarget)
      console.log("route", this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        // Hide Modal
        this.$bvModal.hide('modal-form')
        // Refresh Table
        this.$emit('formOnSubmit', { id: response.success.item.original.id, name: response.success.item.original.name })
        this.$root.$emit("setRoute");
      }
    },

    modalOnSubmit(evt) {
      evt.preventDefault()
      this.formOnSubmit()
    },

    modalOnHide() {
      this.error = validation.clearValidationError(this.error)
      this.form = validation.clearForm(this.form)
      // Reset Form
      delete this.form['_method']
    }

  }

}
</script>

<style>
</style>