<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Kategori Pengeluaran Apotek</b>
      </div>
      <b-button
        squared
        variant="success"
        @click="btnAddOnClick"
        v-if="manipulateBtn == true"
        v-b-modal.modal-form
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :editTarget="editTarget"
              purpose="master"
              ref="table"
              @btnEditOnClick="btnEditOnClick"
              @btnDeleteOnClick="btnDeleteOnClick"
            />
          </template>
        </Card>
      </div>
    </div>

    <b-modal
      id="modal-form"
      :static="true"
      title="Tambah Kategori Pengeluaran Apotek"
      @ok="modalOnSubmit"
      @hidden="modalOnHide"
    >
      <Form
        :form="form"
        :route="route"
        :editTarget="editTarget"
        ref="form"
        purpose="master"
        @formOnSubmit="formOnSubmit"
      />
    </b-modal>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import Card from '@/view/content/Card.vue'
import Form from '@/component/pharmacy/pharmacy-expense-categories/Form.vue'
import Table from '@/component/pharmacy/pharmacy-expense-categories/Table.vue'

export default {

  name: 'List',

  components: {
    Card,
    Table,
    Form,
  },

  data() {
    return {
      // Form
      form: {
        name: '',
        description: ''
      },
      // Form Route
      route: 'pharmacy-expense-categories',
      // Table Items
      items: [],
      // Other
      editTarget: '',
      manipulateBtn: false,
    }
  },

  methods: {

    list() {
      this.$refs.table.list()
    },

    modalOnSubmit(evt) {
      this.$refs.form.modalOnSubmit(evt)
    },

    modalOnHide() {
      this.$refs.form.modalOnHide()
    },

    formOnSubmit() {
      this.list()
    },

    btnAddOnClick() {
      this.editTarget = ''
    },

    setRoute() {
      this.route = "pharmacy-expense-categories"
    },

    async btnEditOnClick(id) {
      this.editTarget = id.toString()
      this.route = "pharmacy-expense-categories/"
      this.form = await module.get('pharmacy-expense-categories/' + id)
      this.form['_method'] = 'put'
      // this.route = "pharmacy-expense-categories"
      // this.setRoute()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('pharmacy-expense-categories/' + id)
      // If Deleted
      if (result) {
        this.list()
      }
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        

        if (access_right[a] == "1405") {
          this.manipulateBtn = true
        }

      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "/pharmacy/dashboard" },
      { title: "Kategori Pengeluaran" },
    ])
    this.setActiveMenu()
    this.$root.$on("setRoute", () => {
      this.setRoute();
    });
  },

}
</script>

<style>
</style>