<template>
  <div>

    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive="sm"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '33%' : '' }"
        />
      </template>
      <template #cell(actions)="data">
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover title="Detail"
            @click="$router.push({ path: '/pharmacy/pharmacy-expense-categories/detail/' + data.item.id })"
          > <i class="fas fa-eye px-0"></i> </b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover title="Edit"
            v-b-modal.modal-form
            @click="btnEditOnClick(data.item.id)"
          > <i class="fas fa-edit px-0"></i> </b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover title="Hapus"
            @click="btnDeleteOnClick(data.item.id)"
          > <i class="fas fa-trash px-0"></i> </b-button>
        </template>
        <template v-if="purpose == 'modal'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="chooseCategory({id: data.item.id, name: data.item.name })"
          > Pilih </b-button>
        </template>
      </template>
    </b-table>

  </div>
</template>

<script>

import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    purpose: String,
  },

  data() {
    return {
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
    }
  },

  methods: {

    async list() {
      this.items = await module.list('pharmacy-expense-categories')
    },

    btnEditOnClick(id) {
      this.$emit('btnEditOnClick', id)
    },

    btnDeleteOnClick(id) {
      this.$emit('btnDeleteOnClick', id)
    },

    chooseCategory(value) {
      this.$emit('chooseCategory', value)
    }

  },

  mounted() {
    this.list()
  }

}
</script>

<style>
</style>